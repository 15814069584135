import React from 'react';

import Content from '@/components/content';
import { Link } from 'gatsby';
import SEO from '@/components/seo';

const NotFoundPage = () => (
	<Content style={{ height: '100%' }}>
		<SEO
			title="DnsAdBlock - page not found"
			description={
				`The requested page could not be found.`
			}
		/>

		<div style={Object.assign({}, {
			display       : 'flex',
			alignItems    : 'center',
			justifyContent: 'center',
			height        : '100%',
		})}>
			<div style={{ width: '100%', textAlign: 'center' }}>
				<span style={{ fontSize: '12rem', lineHeight: '13rem' }}>404</span>
				<h1>NOT FOUND</h1>
				<p style={{ display: 'block' }}>The requested page could not be found</p>
				<p>
					You can go to <Link to={'/'}>
					<b>our homepage</b>
				</Link>
				</p>
			</div>
		</div>
	</Content>
);

export default NotFoundPage;

